import React from 'react'
import { Controller } from 'react-hook-form'

import { CreateTimeoffKinds, TimeoffsAPI } from 'API'
import { DateTime } from 'luxon'

import { isEmpty } from 'lodash'

import { InfoPopover } from 'components/blocks/__v2__'
import {
  FormEmployeesPicker,
  FormFileUpload,
  FormLeaveByEmployeePicker,
  FormLeaveDurationTypeSelector,
  FormLocationPicker,
  FormSelectedDatesList,
  FormShiftConflictActionSelector,
} from 'components/blocks/__v3__/HookForm'
import { DayPicker } from 'components/ui/__v2__/DayPicker'
import { Divider } from 'components/ui/__v2__/Divider'
import { Flex, SpacedRow } from 'components/ui/__v2__/Grid'
import { Button, Modal } from 'components/ui/__v3__'
import {
  FormDayRangePicker,
  FormSelect,
  FormSingleDayPicker,
  FormText,
  FormTimeSelect,
} from 'components/ui/__v3__/HookForm'

import { AccessLevel, LeavePolicyKinds } from 'constants/ids'

import { useAppContext, useI18n } from 'hooks'

import { i18n, TRANSLATIONS } from 'i18n'

import Utils from 'services/Utils'

import { DayHoursBooked, EstimatedBalances } from './components'
import { formToPayload, getDefaultValues } from './helpers'
import { useTimeOffForm } from './hooks'
import { Container } from './styles'
import { ModalProps, TimeoffFormState } from './types'

export function AssignRequestTimeoffModal({
  onClose,
  onCreateTimeoffSuccess,
  kind,
  targetEmployee,
}: ModalProps) {
  const t = useI18n<typeof TRANSLATIONS.timeOffModal>('timeOffModal')
  const { company, viewer } = useAppContext()

  const isGardaEmployee =
    Utils.Viewer.getViewerCurrentAccessLevel(viewer) === AccessLevel.Employee &&
    company.identity.Garda

  const {
    settings: { showLeaveBalanceInfo },
  } = company

  const shouldRenderEmployeePicker =
    kind === CreateTimeoffKinds.Assign && !targetEmployee

  const {
    control,
    employeeId,
    partial,
    effectiveDates,
    partialOvernight,
    locationOptionsForNahdiRequests,
    handleSubmit,
    bookedDaysHours,
    leaveType,
    isValid,
    isSubmitting,
    // @ts-ignore //FIXME:
  } = useTimeOffForm(getDefaultValues(targetEmployee))

  const { createTimeoff, isCreatingTimeoff } = TimeoffsAPI.create(kind)

  const onValidForm = (state: TimeoffFormState) => {
    const payload = formToPayload({
      state,
      isNahdi: company.identity.Nahdi,
      kind,
    })
    createTimeoff(payload, {
      onSuccess: response => {
        if (!response.ok) return

        onClose()
        onCreateTimeoffSuccess?.()
      },
    })
  }

  const renderWholeDayTimeoffPeriodSelector = () => {
    if (isGardaEmployee)
      return (
        <Controller
          control={control}
          name="period"
          render={({ field: { onChange, value: { from } = {} } }) => (
            <DayPicker
              dayOnly
              from={from ? DateTime.fromISO(from).toJSDate() : undefined}
              labelText={t('fields.date')}
              required
              onChange={(payload: string | null) =>
                onChange({ from: payload, to: payload })
              }
            />
          )}
        />
      )

    return (
      <>
        <FormDayRangePicker
          control={control}
          labelText={t('fields.period')}
          name="period"
          required
        />

        {effectiveDates && (
          <FormSelectedDatesList
            control={control}
            labelText={t('fields.includedDays')}
            name="dates"
            required
          />
        )}
      </>
    )
  }

  const renderPartialTimeoffPeriodSelector = () => {
    return (
      <>
        <SpacedRow alignItems="center">
          <SpacedRow>
            <FormSingleDayPicker
              control={control}
              labelText={t('fields.date')}
              name="date"
              required
              width="260px"
              withInput
            />
            <FormTimeSelect
              control={control}
              labelText={t('fields.startTime')}
              name="startTime"
              required
            />
            <FormTimeSelect
              control={control}
              labelText={t('fields.endTime')}
              name="endTime"
              required
            />
          </SpacedRow>

          {partialOvernight && (
            <InfoPopover
              iconSize={14}
              placement="top-start"
              popoverContent={t('overnight')}
            />
          )}
        </SpacedRow>
      </>
    )
  }

  return (
    <Modal.Container isOpen>
      <Modal.Close disabled={isCreatingTimeoff} onClose={onClose} />
      <Modal.Title>{t(`title.${kind}`)}</Modal.Title>

      <Divider borderWidth={2} mb={4} />
      <Container>
        <form onSubmit={handleSubmit(onValidForm)}>
          <Flex flexDirection="column" gap={2}>
            <FormLeaveDurationTypeSelector
              alignSelf="end"
              control={control}
              name="partial"
            />

            {shouldRenderEmployeePicker && (
              <FormEmployeesPicker
                control={control}
                labelText={t('fields.employee')}
                name="employee"
                required
              />
            )}

            {partial
              ? renderPartialTimeoffPeriodSelector()
              : renderWholeDayTimeoffPeriodSelector()}

            <FormLeaveByEmployeePicker
              control={control}
              effectiveDates={effectiveDates}
              employeeId={employeeId}
              isDisabled={!employeeId || isEmpty(effectiveDates)}
              labelText={t('fields.leaveType')}
              name="leaveType"
              partial={partial === true ? true : undefined}
              required
            />

            {/**
             * Upd 2021-08-10
             * Managers can assign to any location
             * But employees can only request one of their own
             */}
            {company.identity.Nahdi && kind === CreateTimeoffKinds.Assign && (
              <FormLocationPicker
                control={control}
                isDisabled={!employeeId}
                labelText={t('fields.location')}
                name="location"
                required
              />
            )}

            {company.identity.Nahdi && kind === CreateTimeoffKinds.Request && (
              <FormSelect
                control={control}
                isDisabled={!employeeId}
                labelText={t('fields.location')}
                name="location"
                options={locationOptionsForNahdiRequests}
              />
            )}

            <FormText
              control={control}
              labelText={t('fields.comments')}
              name="reason"
              width={1}
            />

            <FormFileUpload
              control={control}
              labelText={t('fields.supprotingDocument')}
              name="document"
            />

            {kind === CreateTimeoffKinds.Assign && (
              <>
                <Divider borderWidth={1} my={2} />
                <Flex flexDirection="column">
                  <FormShiftConflictActionSelector
                    control={control}
                    description={t('conflictDescription')}
                    excludeNoneAction
                    name="scheduleConflictType"
                    withErrorConflict
                  />
                </Flex>
              </>
            )}

            {bookedDaysHours &&
              !company.identity.Aldo &&
              !showLeaveBalanceInfo &&
              leaveType?.data.policyKind === LeavePolicyKinds.Manual && (
                <>
                  <Divider borderWidth={1} />
                  <DayHoursBooked
                    days={bookedDaysHours.days}
                    hours={bookedDaysHours.hours}
                  />
                </>
              )}

            {showLeaveBalanceInfo &&
              leaveType?.data.policyKind === LeavePolicyKinds.Manual && (
                <>
                  <Divider borderWidth={1} my={2} />
                  <EstimatedBalances control={control} />
                </>
              )}
          </Flex>
        </form>
      </Container>

      <Modal.Actions justifyContent="center">
        <Button
          disabled={isCreatingTimeoff}
          mr={17}
          secondary
          width={180}
          onClick={onClose}
        >
          {i18n('actions.cancel')}
        </Button>

        <Button
          disabled={!isValid || isSubmitting || isCreatingTimeoff}
          width={180}
          onClick={handleSubmit(onValidForm)}
        >
          {i18n('actions.confirm')}
        </Button>
      </Modal.Actions>
    </Modal.Container>
  )
}
