import { DateTime } from 'luxon'

import difference from 'lodash/difference'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import reduce from 'lodash/reduce'

import { isDayContainsTimeOff } from 'constants/timeoff'

import { isSameDay } from 'helpers/date'

export const SHIFTS_REPEAT = {
  NONE: 'common.none',
  UNTIL: 'common.until',
}

export const SHIFT_STATUSES = {
  published: 'published',
  unpublished: 'unpublished',
}

export const DATE_LOCALE_STRING_FORMAT = { hour: '2-digit', minute: '2-digit' }

export const SHIFT_ALREADY_STARTED_THRESHOLD = 2 * 60

export function isDayShift(shift, day) {
  const { startAt, finishAt } = shift
  return (
    DateTime.fromISO(startAt).diff(DateTime.fromISO(day).startOf('day')) >= 0 &&
    ((!finishAt && isSameDay(startAt, day)) ||
      DateTime.fromISO(finishAt).diff(DateTime.fromISO(day).endOf('day')) <= 0)
  )
}

export function isOvernightShift(shift, day) {
  const { startAt, finishAt } = shift

  return (
    DateTime.fromISO(finishAt).diff(DateTime.fromISO(day).endOf('day')) >= 0 &&
    DateTime.fromISO(startAt).diff(DateTime.fromISO(day).endOf('day')) <= 0
  )
}

export const isCorrectedOvernightShift = shift => {
  return shift?.finishAt.day > shift?.startAt.day
}

export const findEmployeeTimeoff = (employee, shiftStartAt) => {
  if (isEmpty(employee.timeoffs)) {
    return null
  }

  return employee.timeoffs.find(timeoff =>
    isDayContainsTimeOff(timeoff, shiftStartAt),
  )
}

export const COLORS = {
  yellow: '#ffe94d',
  orange: '#f7a959',
  red: '#de6f6f',
  pink: '#e997d3',
  purple: '#a797e8',
  blue: '#97cce8',
  lightBlue: '#50d1dd',
  green: '#9fe897',
  black: '#9b9b9b',
}

export const DISABLED_COLOR = '#c0c0c0'

export const getAvailableShiftsForDepartmentManager = (shifts, viewer) => {
  const viewerDepartmentsIds = reduce(
    viewer.managers,
    (ids, manager) => {
      const departments = get(manager, 'departments', [])
      if (!isEmpty(departments)) {
        const departmentsIds = map(departments, 'id')
        ids = [...ids, ...departmentsIds]
      }
      return ids
    },
    [],
  )
  if (!isEmpty(viewerDepartmentsIds)) {
    return reduce(
      shifts,
      (availableShifts, shift) => {
        const { shiftsJobs } = shift
        const shiftsJobsDepartmentIds = map(shiftsJobs, 'job.department.id')
        if (
          difference(shiftsJobsDepartmentIds, viewerDepartmentsIds).length === 0
        ) {
          availableShifts = [...availableShifts, shift]
        }
        return availableShifts
      },
      [],
    )
  }
  return []
}

export const SHIFTS_VIEW_SORT_OPTIONS = {
  role: 'common.role',
  name: 'common.name',
  time: 'common.time',
}

export const pausesToOptions = (item, id) => ({
  id: item.id || id,
  data: { ...item },
})

export const rolesToOptions = item => ({
  id: `shift_${item?.id}_department_${item?.department?.id}_job_${item?.job?.id}`,
  data: {
    id: item?.job?.id,
    name: item?.job?.name,
    amount: item?.quantity ?? 0,
    shiftJobId: item?.id,
    departmentId: item?.department?.id,
    departmentName: item?.department?.name,
    viewerHasAccess: item?.viewerHasAccess,
    shiftJobGroups: item?.shiftJobGroups?.map(shiftJobGroup => ({
      id: shiftJobGroup.group.id,
      value: shiftJobGroup.group.id,
      label: shiftJobGroup.group.name,
    })),
    open: item?.open,
  },
})

export const SEC_IN_MIN = 60
export const SEC_IN_HOUR = 60 * SEC_IN_MIN
export const SEC_IN_DAY = 24 * SEC_IN_HOUR
export const LATEST_DAY_TIME = 23.5
export const DAILY_WORKING_HOURS = 8

export const SHIFT_STATES = {
  accepted_by_employer: 'accepted_by_employer',
  pending: 'pending',
  rejected: 'rejected',
}
