import { SelectOption } from 'Types/common'

import { i18n } from 'i18n'

import { CandidateTab } from './types'

export const TAB_OPTIONS: Array<SelectOption<CandidateTab>> = [
  { value: 'available', label: i18n('shiftAssign.candidates.tabs.available') },
  {
    value: 'unavailable',
    label: i18n('shiftAssign.candidates.tabs.unavailable'),
  },
  {
    value: 'availableMissingQualifications',
    label: i18n('shiftAssign.candidates.tabs.availableMissingQualifications'),
  },
]
