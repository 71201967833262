import React, { useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'

import { useTheme } from 'styled-components'

import { vestResolver } from '@hookform/resolvers/vest'
import { ShiftAPIItem } from 'API'
import { DateTime } from 'luxon'
import { Weekday } from 'Types/common'

import { Flex, Span } from 'components/ui/__v2__/Grid'
import { Button, Modal } from 'components/ui/__v3__/'
import {
  FormCheckbox,
  FormSingleDayPicker,
  FormTimeSelect,
} from 'components/ui/__v3__/HookForm'

import { useI18n } from 'hooks'

import { i18n, TRANSLATIONS } from 'i18n'

import { EmployeesTable } from './components'
import {
  mapFormDataToServerData,
  mapServerDataToFormData,
  validator,
} from './helpers'
import { Container } from './styles'
import { ShiftMoveModalFormState } from './types'

type Props = {
  weekdayStart: Weekday
  shift: ShiftAPIItem
  onMove: (input: Gateway.MoveSchedulesFromShiftInput) => Promise<void>
  onClose: () => void
}

export function ShiftMoveModal({
  weekdayStart,
  shift,
  onMove,
  onClose,
}: Props) {
  const theme = useTheme()
  const t = useI18n<typeof TRANSLATIONS.shiftMoveModal>('shiftMoveModal')

  const {
    shiftJobIds,
    defaultDate,
    defaultStartTime,
    defaultEndTime,
  } = useMemo(() => mapServerDataToFormData(shift), [shift])

  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit: formHandleSubmit,
    setValue,
  } = useForm<ShiftMoveModalFormState>({
    mode: 'onBlur',
    resolver: vestResolver(validator),
    defaultValues: {
      date: defaultDate,
      startTime: defaultStartTime,
      endTime: defaultEndTime,
      selectedScheduleIds: new Set(),
      reduceQuantity: false,
    },
  })

  const handleSubmit = async (values: ShiftMoveModalFormState) => {
    const {
      scheduleIds,
      startAt,
      endAt,
      reduceQuantity,
    } = mapFormDataToServerData({
      values,
      timezone: shift.location.settings.timezone,
    })

    await onMove({
      shiftId: shift.id,
      scheduleIds,
      reduceQuantity,
      startAt,
      endAt,
    })

    onClose()
  }

  const [startTime, endTime] = useWatch({
    control,
    name: ['startTime', 'endTime'],
  })

  const disableConfirmButton = !isValid || isSubmitting
  const differenceBetweenTimes = endTime - startTime

  return (
    <Modal.Container isOpen>
      <Modal.Close onClose={onClose} />
      <Modal.Title>{t('title')}</Modal.Title>

      <form onSubmit={formHandleSubmit(handleSubmit)}>
        <Container>
          <Span>{t('subtitle')}</Span>

          <Flex alignItems="flex-end" flexDirection="column" gap={1}>
            <Flex justifyContent="space-between" width="100%">
              <FormSingleDayPicker
                control={control}
                disablePast
                disabled={isSubmitting}
                format={DateTime.DATE_MED}
                labelText={t('form.date')}
                minWidth="230px"
                name="date"
                workweekStartDay={weekdayStart}
              />

              <FormTimeSelect
                control={control}
                disabled={isSubmitting}
                labelText={t('form.startTime')}
                name="startTime"
              />

              <FormTimeSelect
                control={control}
                disabled={isSubmitting}
                labelText={t('form.endTime')}
                name="endTime"
              />
            </Flex>

            {differenceBetweenTimes < 0 && (
              <Span color={theme.layout.primary} fontSize="12px">
                {i18n('shifts.looksLikeNight')}
              </Span>
            )}
          </Flex>

          <EmployeesTable
            control={control}
            disabled={isSubmitting}
            setValue={setValue}
            shiftJobIds={shiftJobIds}
          />

          <FormCheckbox
            control={control}
            disabled={isSubmitting}
            labelText={t('form.reduceQuantity')}
            name="reduceQuantity"
            size={18}
          />
        </Container>

        <Flex justifyContent="space-between" mt={3}>
          <Button secondary width={180} onClick={onClose}>
            {t('actions.cancel')}
          </Button>

          <Button
            disabled={disableConfirmButton}
            loading={isSubmitting}
            width={180}
          >
            {t('actions.copy')}
          </Button>
        </Flex>
      </form>
    </Modal.Container>
  )
}
