import React, { useMemo, useState } from 'react'

import { HiOutlineAdjustments as SettingsIcon } from 'react-icons/hi'
import { MdFilterList as FilterIcon } from 'react-icons/md'

import { InfoPopover } from 'components/blocks/__v2__'
import { Divider } from 'components/ui/__v2__/Divider'
import { SpacedColumn, SpacedRow, Span } from 'components/ui/__v2__/Grid'
import { Select } from 'components/ui/__v3__'

import { useAppContext, useI18n, usePopover } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import {
  AvailableTab,
  FilterForm,
  OptionsForm,
  UnavailableTab,
} from './components'
import { TAB_OPTIONS } from './config'
import { useCandidateFiltersForm, useUnavailableOptionsForm } from './hooks'
import {
  AvailableTooltipContent,
  FilterHandle,
  PopoverContainer,
} from './styles'

import { useShiftAssignmentContext } from '../../context'
import { SectionTitle } from '../../styles'

export function Candidates() {
  const { company } = useAppContext()
  const { selectedShiftJob } = useShiftAssignmentContext()

  const t = useI18n<typeof TRANSLATIONS.shiftAssign.candidates>(
    'shiftAssign.candidates',
  )

  // Move to Context?
  // TODO: need a proper solution
  const AVAILABILITY_IS_A_HARD_CONSTRAINT =
    company.identity.Keywords || company.identity.Icc || company.identity.Tbs

  const [tab, setTab] = useState(TAB_OPTIONS[0])

  // TODO: Don't reuse this, needs proper implementation
  const {
    open: filterOpen,
    refs: fliterRefs,
    floatingStyles: filterFloatingStyles,
    getReferenceProps: filterGetReferenceProps,
    getFloatingProps: filterGetFloatingProps,
  } = usePopover()

  const {
    open: optionsOpen,
    refs: optionsRefs,
    floatingStyles: optionsFloatingStyles,
    getReferenceProps: optionsGetReferenceProps,
    getFloatingProps: optionsGetFloatingProps,
  } = usePopover()

  const {
    customCvFieldsFilter,
    control: filterControl,
  } = useCandidateFiltersForm()
  const { control: optionsControl, reason } = useUnavailableOptionsForm()

  const tabOptions = useMemo(() => {
    if (!selectedShiftJob || selectedShiftJob?.shiftJobGroups?.length === 0)
      return TAB_OPTIONS.filter(
        tab => tab.value !== 'availableMissingQualifications',
      )

    return TAB_OPTIONS
  }, [selectedShiftJob])

  return (
    <SpacedColumn>
      <SpacedRow alignItems="center" justifyContent="space-between">
        <SpacedRow alignItems="center" space={1}>
          <SectionTitle>{t('title')}</SectionTitle>

          <FilterHandle
            ref={fliterRefs.setReference}
            {...filterGetReferenceProps()}
          >
            <FilterIcon />
          </FilterHandle>
        </SpacedRow>

        <SpacedRow alignItems="center" space={1}>
          {tab.value === 'unavailable' && (
            <FilterHandle
              ref={optionsRefs.setReference}
              {...optionsGetReferenceProps()}
            >
              <SettingsIcon />
            </FilterHandle>
          )}
          {tab.value === 'available' && (
            // @ts-ignore
            <InfoPopover
              popoverContent={
                <AvailableTooltipContent>
                  <Span mb={1}>{t('availableDetails.description')}</Span>
                  <Divider />
                  <Span>{t('availableDetails.notHavingShiftConflicts')}</Span>
                  <Span>{t('availableDetails.notHavingTimeoffConflicts')}</Span>
                  <Span>{t('availableDetails.notHavingTimeoffConflicts')}</Span>
                  <Span>{t('availableDetails.hasGroupQualifications')}</Span>
                  {/* eslint-disable-next-line prettier/prettier */}
                  {AVAILABILITY_IS_A_HARD_CONSTRAINT && (
                    <Span>
                      {t('availableDetails.notHavingAvailabilityConflicts')}
                    </Span>
                  )}
                </AvailableTooltipContent>
              }
              trigger="click"
            />
          )}
          <Select
            // @ts-ignore
            isClearable={false}
            options={tabOptions}
            value={tab}
            wrapper={{ width: '160px' }}
            onChange={setTab}
          />
        </SpacedRow>
      </SpacedRow>

      {tab.value === 'available' ||
      tab.value === 'availableMissingQualifications' ? (
        <AvailableTab
          customCvFields={customCvFieldsFilter}
          showMissedQualificationsEmployees={
            tab.value === 'availableMissingQualifications'
          }
        />
      ) : (
        <UnavailableTab customCvFields={customCvFieldsFilter} reason={reason} />
      )}
      {filterOpen && (
        <PopoverContainer
          ref={fliterRefs.setFloating}
          style={filterFloatingStyles}
          {...filterGetFloatingProps()}
          width={480}
        >
          <FilterForm control={filterControl} />
        </PopoverContainer>
      )}

      {optionsOpen && (
        <PopoverContainer
          ref={optionsRefs.setFloating}
          style={optionsFloatingStyles}
          {...optionsGetFloatingProps()}
        >
          <OptionsForm control={optionsControl} />
        </PopoverContainer>
      )}
    </SpacedColumn>
  )
}
