import { useCallback, useEffect, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { DateTime } from 'luxon'

import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'

import { thinFormLocation } from 'components/blocks/__v3__/HookForm/FormLocationPicker'

import { ScheduleConflictActionTypes } from 'constants/ids'

import usePrevious from 'hooks/usePrevious'

import { locationToOption } from 'services/Options'

import { DEFAULT_PARTIAL_START_END_TIME } from './config'

import {
  currentLeaveCoversNewEffectiveDates,
  generateDaysList,
  hoursFromDateAndStartEndSeconds,
  includedDates,
  partialInputsToDateTimes,
  validator,
} from '../helpers'
import { TimeoffFormState } from '../types'

export function useTimeOffForm(defaultValues: TimeoffFormState) {
  const {
    control,
    handleSubmit,
    setValue,
    resetField,
    clearErrors,
    formState: { isSubmitting, isValid },
  } = useForm<TimeoffFormState>({
    defaultValues,
    resolver: yupResolver(validator),
    mode: 'onTouched',
  })

  const [
    partial,
    employee,
    period,
    leaveType,
    dates,
    date,
    startTime,
    endTime,
  ] = useWatch({
    control,
    name: [
      'partial',
      'employee',
      'period',
      'leaveType',
      'dates',
      'date',
      'startTime',
      'endTime',
    ],
  })

  const partialOvernight = Boolean(
    partial && isNumber(startTime) && isNumber(endTime) && endTime < startTime,
  )

  const employeeTimezone =
    employee?.employee?.branch?.settings?.timezone ?? 'local'

  // Check if start date is in the past and set default value for conflict type
  const isStartDateInThePast = useMemo(() => {
    if (partial && date) {
      const { startDatetime } = partialInputsToDateTimes(
        date,
        startTime!,
        endTime!,
      )

      return (
        startDatetime.setZone(employeeTimezone, { keepLocalTime: true }) <
        DateTime.now().setZone(employeeTimezone, { keepLocalTime: true })
      )
    }

    return period?.from
      ? DateTime.fromISO(period.from, { zone: employeeTimezone }).startOf(
          'day',
        ) <
          DateTime.now()
            .setZone(employeeTimezone, { keepLocalTime: true })
            .startOf('day')
      : false
  }, [startTime, endTime, date, partial, period, employeeTimezone])
  useEffect(() => {
    if (!isStartDateInThePast) {
      setValue('scheduleConflictType', ScheduleConflictActionTypes.Split)
    } else {
      setValue('scheduleConflictType', ScheduleConflictActionTypes.Error)
    }
  }, [setValue, isStartDateInThePast])

  // Effective dates for both Partial and Whole day
  const effectiveDates = useMemo(() => {
    if (!partial && period?.from && period?.to) {
      return period
    }

    if (partial && date && isNumber(startTime) && isNumber(endTime)) {
      return {
        from: date,
        to: partialOvernight
          ? DateTime.fromISO(date).plus({ days: 1 }).toISODate()
          : date,
      }
    }

    return undefined
  }, [date, endTime, partialOvernight, partial, period, startTime])

  const bookedDaysHours = useMemo(() => {
    if (employee) {
      const { dayHourEquivalence } = employee.employee.leaveSettings
      if (partial && date && isNumber(startTime) && isNumber(endTime)) {
        const hours = hoursFromDateAndStartEndSeconds(date, startTime, endTime)
        return {
          days: hours / dayHourEquivalence,
          hours,
        }
      }
      if (!partial && !isEmpty(dates)) {
        const datesIncludedCount = dates.filter(includedDates).length
        return {
          days: datesIncludedCount,
          hours: datesIncludedCount * dayHourEquivalence,
        }
      }
    }
    return undefined
  }, [date, dates, employee, endTime, partial, startTime])

  const prevFrom = usePrevious(period?.from)
  const prevTo = usePrevious(period?.to)

  // Whole day: set dates for the Excluded fays field
  const setDatesForSelector = useCallback(() => {
    if (
      !partial &&
      period &&
      (period?.from !== prevFrom || period?.to !== prevTo)
    ) {
      // @ts-ignore
      const days = generateDaysList(period)
      setValue('dates', days)
    }
  }, [partial, period, prevFrom, prevTo, setValue])
  useEffect(() => setDatesForSelector(), [setDatesForSelector])

  // Clean the unapplicable Leave if effective dates are updated
  const prevEffectiveDates = usePrevious(effectiveDates)
  useEffect(() => {
    if (
      leaveType &&
      effectiveDates &&
      prevEffectiveDates &&
      (prevEffectiveDates.from !== effectiveDates.from ||
        prevEffectiveDates.to !== effectiveDates.to)
    ) {
      const selectedLeaveEffectiveDates = leaveType.data.effectiveDates
      if (
        !currentLeaveCoversNewEffectiveDates(
          selectedLeaveEffectiveDates,
          effectiveDates,
        )
      ) {
        resetField('leaveType')
      }
    }
  }, [
    effectiveDates,
    leaveType,
    prevEffectiveDates,
    resetField,
    setDatesForSelector,
    setValue,
  ])

  // Reset leave if employee changed
  const prevEmployee = usePrevious(employee)
  useEffect(() => {
    if (
      prevEmployee &&
      employee &&
      leaveType &&
      prevEmployee.id !== employee.id
    ) {
      resetField('leaveType')
    }
  })

  // Reset leave if set to Partial from Whole, clear errors, preset
  const prevPartial = usePrevious(partial)
  useEffect(() => {
    if (partial && !prevPartial) {
      resetField('leaveType')
      setValue('startTime', DEFAULT_PARTIAL_START_END_TIME.startTime)
      setValue('endTime', DEFAULT_PARTIAL_START_END_TIME.endTime)
      clearErrors(['period'])
    }

    if (!partial && prevPartial) {
      clearErrors(['date', 'startTime', 'endTime'])
    }
  }, [clearErrors, partial, prevPartial, resetField, setValue])

  // ====== NAHDI SPECIFIC =====
  // Set defaul location to primary Branch
  useEffect(() => {
    if (employee) {
      setValue(
        'location',
        // @ts-ignore
        thinFormLocation(locationToOption(employee.employee.branch)),
      )
    }
  }, [employee, setValue])

  // If the employee requests, the only options are his locations
  const locationOptionsForNahdiRequests = employee?.employee.branches ?? []

  // ====== EOF NAHDI SPECIFIC =====

  return {
    control,
    employeeId: employee?.id,
    locationOptionsForNahdiRequests,
    partial,
    effectiveDates,
    partialOvernight,
    isStartDateInThePast,
    isSubmitting,
    isValid,
    bookedDaysHours,
    leaveType,
    handleSubmit,
  }
}
