import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'

import { vestResolver } from '@hookform/resolvers/vest'

import { InfoPopover } from 'components/blocks/__v2__'
import { Flex, SpacedRow, Span } from 'components/ui/__v2__/Grid'
import { Button } from 'components/ui/__v3__'
import { FormSelect, FormSwitch } from 'components/ui/__v3__/HookForm'
import { Modal } from 'components/ui/__v3__/Modal'

import { Feature } from 'constants/products'

import { hasFeature } from 'helpers/access'

import { useAppContext } from 'hooks'

import { i18n } from 'i18n'

import { showToast } from 'services/Toasts'
import Utils from 'services/Utils'

import { FormEarlyClockIn, FormRapidMode } from './components'
import { DEFAULT_WEEK_START_DAY, MINUTES_OPTIONS } from './constants'
import { getInitialValues, useSchema } from './formConfig'
import { mapFormStateToServerData } from './mappers'
import { Container } from './styles'
import { GlobalSettingsFormState } from './types'

interface Props {
  onClose: () => void
  isOpen: boolean
  onUpdateCompanySettings: (
    params: ReturnType<typeof mapFormStateToServerData>,
  ) => Promise<void>
}

export const GlobalSettingsModal = ({
  onClose,
  isOpen,
  onUpdateCompanySettings,
}: Props) => {
  const { products, isAldo, companySettings } = useAppContext()

  const hasAutoShiftNameFeature = hasFeature(Feature.AutoShiftName, products)

  const getWeekStartDayOptions = useCallback(() => {
    return Utils.DateTime.getWeekDayNumbers(DEFAULT_WEEK_START_DAY).map(
      day => ({
        value: day,
        label: i18n(`settings.calendarStartDay.days.${day}`),
      }),
    )
  }, [])

  const WEEK_START_DAY_OPTIONS = useMemo(() => getWeekStartDayOptions(), [
    getWeekStartDayOptions,
  ])

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { isSubmitting, isValid },
  } = useForm<GlobalSettingsFormState>({
    resolver: vestResolver(useSchema(hasAutoShiftNameFeature)),
    mode: 'all',
    defaultValues: getInitialValues(companySettings, hasAutoShiftNameFeature),
  })

  const [
    allowFutureManualTimecardApprove,
    allowDepartmentManagerMutualApprove,
    disableEmployeeProfileEdit,
    disableEmployeeBreaks,
    earlyClockIn,
    earlyClockInLimitHours,
    showWagesToManagers,
    rapidClockInMode,
  ] = useWatch({
    control,
    name: [
      'allowFutureManualTimecardApprove',
      'allowDepartmentManagerMutualApprove',
      'disableEmployeeProfileEdit',
      'disableEmployeeBreaks',
      'earlyClockIn',
      'earlyClockInLimitHours',
      'showWagesToManagers',
      'rapidClockInMode',
    ],
  })

  async function handleSaveGlobalSettings(values: GlobalSettingsFormState) {
    const params = mapFormStateToServerData(values, hasAutoShiftNameFeature)
    // @ts-ignore
    await onUpdateCompanySettings(params).then((response: JsonApi.Response) => {
      if (!response.ok) {
        showToast({
          type: 'error',
          title: i18n('alertMessages.update.error.title'),
          content: i18n('alertMessages.update.error.message'),
        })
      } else {
        showToast({
          type: 'success',
          title: i18n('alertMessages.update.success.title'),
          content: i18n('alertMessages.update.success.message'),
        })
        onClose()
      }
    })
  }

  useEffect(() => {
    if (earlyClockInLimitHours === 24) {
      setValue('earlyClockInLimitMinutes', 0)
      trigger('earlyClockInLimitMinutes')
    }
  }, [earlyClockInLimitHours, setValue, trigger])

  useEffect(() => {
    if (!rapidClockInMode.enabled) {
      setValue('rapidClockInMode.limit', '0')
      trigger('rapidClockInMode.limit')
    }
  }, [rapidClockInMode.enabled, setValue, trigger])

  return (
    <Modal.Container isOpen={isOpen}>
      <Modal.Close onClose={onClose} />
      <Modal.Title>{i18n('settings.globalSettings')}</Modal.Title>
      <form onSubmit={handleSubmit(handleSaveGlobalSettings)}>
        <Container>
          <FormSwitch<GlobalSettingsFormState>
            control={control}
            label={i18n(
              `settings.employeeProfileEdit${
                disableEmployeeProfileEdit ? 'Unchecked' : 'Checked'
              }`,
            )}
            name="disableEmployeeProfileEdit"
          />

          <FormSwitch<GlobalSettingsFormState>
            control={control}
            label={i18n(
              `settings.approvingFutureManualTimecards${
                allowFutureManualTimecardApprove ? 'On' : 'Off'
              }`,
            )}
            name="allowFutureManualTimecardApprove"
          />

          <FormSwitch<GlobalSettingsFormState>
            control={control}
            label={i18n(
              `settings.allowDepartmentManagerMutualApprove.${
                allowDepartmentManagerMutualApprove ? 'on' : 'off'
              }`,
            )}
            name="allowDepartmentManagerMutualApprove"
          />

          {hasAutoShiftNameFeature && (
            <Flex>
              <FormSwitch<GlobalSettingsFormState>
                control={control}
                label={i18n(`settings.autoShiftName.Checked`)}
                name="autoShiftName"
              />
              <Flex ml={2} mt="3px">
                <InfoPopover
                  iconSize={undefined}
                  maxWidth={200}
                  placement="bottom"
                  popoverContent={i18n(`settings.autoShiftName.helper`)}
                />
              </Flex>
            </Flex>
          )}

          <FormSwitch<GlobalSettingsFormState>
            control={control}
            label={i18n('settings.newScheduleEmail')}
            name="sendScheduleEmails"
          />

          {!isAldo && (
            <FormSwitch<GlobalSettingsFormState>
              control={control}
              label={i18n(
                `settings.showWagesToManagers.${
                  showWagesToManagers ? 'on' : 'off'
                }`,
              )}
              name="showWagesToManagers"
            />
          )}

          <FormSwitch<GlobalSettingsFormState>
            control={control}
            label={i18n(
              `settings.disableEmployeeBreaks.${
                disableEmployeeBreaks ? 'on' : 'off'
              }`,
            )}
            name="disableEmployeeBreaks"
          />

          <FormEarlyClockIn
            control={control}
            isEarlyClockInEnabled={earlyClockIn}
            minutesOptions={
              earlyClockInLimitHours === 24
                ? MINUTES_OPTIONS.slice(0, 1)
                : MINUTES_OPTIONS
            }
          />

          <FormRapidMode
            control={control}
            rapidClockInMode={rapidClockInMode}
          />

          <Span width="150px">
            <FormSelect<GlobalSettingsFormState>
              control={control}
              isClearable={false}
              labelText={i18n('settings.weekStartDay')}
              name="calendarStartDay"
              options={WEEK_START_DAY_OPTIONS}
              returnOptionObject={false}
              withPortal
            />
          </Span>
        </Container>

        <SpacedRow justifyContent="center" mt={5}>
          <Button disabled={isSubmitting || !isValid} small>
            {i18n('actions.save')}
          </Button>
        </SpacedRow>
      </form>
    </Modal.Container>
  )
}
