import React from 'react'

import { ShiftValidationRuleAPIItem } from 'API'

import { RuleParametersComparison } from 'constants/gatewayGraphQL'

import Utils from 'services/Utils'

type Props = {
  rule: ShiftValidationRuleAPIItem
  withSemicolon?: boolean
  formSubPath:
    | 'shiftLengthThreshold'
    | 'shiftRestTime'
    | 'employeeAssignmentThreshold'
}

export function HoursRangeCondition({
  rule,
  withSemicolon,
  formSubPath,
}: Props) {
  const showThan = rule.parameters[formSubPath]?.operator
    ? [
        RuleParametersComparison.GreaterThan,
        RuleParametersComparison.LessThan,
      ].includes(rule.parameters[formSubPath]?.operator!)
    : null

  const showTo = !!rule.parameters[formSubPath]?.toSeconds

  return (
    <>
      {Utils.Rule.getComparisonText(rule.parameters[formSubPath]?.operator)}{' '}
      {showThan && 'than'}{' '}
      <>
        {Utils.DateTime.secondsToHours(
          rule.parameters[formSubPath]?.fromSeconds!,
        )}
        {!showTo && withSemicolon && ','}
      </>{' '}
      {showTo && (
        <>
          and{' '}
          {Utils.DateTime.secondsToHours(
            rule.parameters[formSubPath]?.toSeconds!,
          )}
          {withSemicolon && ','}
        </>
      )}{' '}
      hours
    </>
  )
}
