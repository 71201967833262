import { useApolloQuery } from 'API/services/Apollo'

import { EstimateBalanceImpactQuery } from './GraphQL'

export function useEstimateBalanceImpact({
  enabled,
  input,
  onCompleted,
}: {
  enabled: boolean
  input: Gateway.EstimateBalanceImpactInput
  onCompleted?: () => void
}) {
  const { data, loading, error } = useApolloQuery<
    QueryData<'estimateBalanceImpact'>,
    Gateway.QueryEstimateBalanceImpactArgs
  >(EstimateBalanceImpactQuery, {
    fetchPolicy: 'network-only',
    skip: !enabled,
    variables: {
      input,
    },
    onCompleted,
  })

  return {
    data: data?.estimateBalanceImpact ?? [],
    loading,
    hasError: !!error,
  }
}
